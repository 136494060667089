const { protocol, hostname, port } = window.location;
const portForOrigin = port ? `:${port}` : '';
const origin = `${protocol}//${hostname}${portForOrigin}`;

const config = {
  protocol,
  hostname,
  port,
  origin
};

export default config;
