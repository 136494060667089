import querystring from 'query-string';
import actions from './actions';
import config from '../../config';

const { saveToken } = actions;
const { origin, authorizationEndpoint, clientId } = config;
const buildQueryString = items => querystring.stringify(items, { sort: false });

const authenticate = () => async () => {
    const query = {
        response_type: 'token',
        client_id: clientId,
        scope: 'openid',
        redirect_uri: `${origin}/token`
    };

    const url = `${authorizationEndpoint}?${buildQueryString(query)}`;
    window.location.replace(url);
};

const operations = {
    saveToken,
    authenticate
};

export default operations;
