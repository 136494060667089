import common from './config';

const getConfig = () => ({
        ...common,
        clientId: process.env.REACT_APP_SICO_ETRAVEL_CLIENTID,
        authorizationEndpoint: process.env.REACT_APP_SICO_ETRAVEL_AUTHURL
    });

const config = getConfig();

export default config;
