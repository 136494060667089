import fetch from './fetch';

async function login(token, useLegacy) {
  const query = useLegacy ? '?useLegacy=true' : '';
  const url = 'api/login' + query;
  return await fetch(url, 'POST', {}, token);
}

const services = {
  login
};

export default services;
