import PropTypes from 'prop-types';
import React from 'react';
import store from 'store2';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children, isAuthenticated, redirectTo, useLegacy}) {
  store("use_legacy", useLegacy ? true : false);
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

PrivateRoute.propTypes = {
  children: PropTypes.any.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string.isRequired,
  useLegacy: PropTypes.bool
};

export default connect(state => {
  const { token } = state.authentication;
  return { isAuthenticated: token != null && token.length > 0 };
})(PrivateRoute);
