import types from './types';

const saveToken = token => ({
    type: types.SAVE_TOKEN,
    token
});

const actions = {
    saveToken
};

export default actions;
