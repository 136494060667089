import types from './types';

const initialState = { token: '' };

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SAVE_TOKEN: {
            return {
                ...state,
                token: action.token
            };
        }
        default:
            return state;
    }
};

export default reducer;
