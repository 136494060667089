/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React from 'react';
import cx from 'classnames';

function Button(props) {
  const {
    primary,
    secondary,
    confirm,
    full,
    responsive,
    disabled,
    type,
    label,
    icon,
    className,
    onClick,
    ...other
  } = props;

  const styled = primary || secondary || confirm;
  const classes = cx(
    {
      button: true,
      'button--primary': primary || !styled,
      'button--secondary': secondary,
      'button--confirm': confirm,
      'button--full': full,
      'button--responsive': responsive,
      button__icon: icon,
      disabled
    },
    className
  );

  return (
    <button {...other} className={classes} type={type} disabled={disabled} onClick={onClick}>
      {label}
    </button>
  );
}

Button.defaultProps = {
  primary: false,
  secondary: false,
  confirm: false,
  full: false,
  responsive: false,
  disabled: false,
  type: 'button'
};

export default Button;
