const getJson = (method, payload) => (method !== 'GET' ? JSON.stringify(payload) : undefined);

const getDefaultHeaders = token => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`
});

export const fetchRaw = async (url, method, body, headers = {}) => {
  const options = {
    method,
    headers,
    body
  };

  return fetch(url, options);
};

export const fetchJson = async (url, method, payload, token) => {
  const headers = getDefaultHeaders(token);
  const body = getJson(method, payload);
  const response = await fetchRaw(url, method, body, headers);
  if (response.status === 204) {
    return {};
  }

  const responseBody = await response.json();

  if (response.status >= 300) {
    const error = new Error(responseBody.message);
    error.response = responseBody;
    error.status = response.status;
    throw error;
  }

  return responseBody;
};

export default fetchJson;
