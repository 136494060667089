import types from './types';

const initialState = {
  inProgress: false,
  hasError: false,
  error: {}
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.updateProfile: {
      return { ...initialState, inProgress: true };
    }

    case types.updateProfileFailed: {
      return {
        ...state,
        hasError: true,
        inProgress: false,
        error: action.error
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
