import React from 'react';

function Robot() {
  return (
    <svg className="Robot" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <rect fill="none" width="30" height="30" />
      <g>
        <g>
          <path
            d="M12.3,16.7c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L12.4,14l1.3-1.3c0.4-0.4,0.4-1,0-1.4
                        s-1-0.4-1.4,0L11,12.6l-1.3-1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L9.6,14l-1.3,1.3c-0.4,0.4-0.4,1,0,1.4C8.5,16.9,8.7,17,9,17
                        s0.5-0.1,0.7-0.3l1.3-1.3L12.3,16.7z"
          />
          <path
            d="M21.7,11.3c-0.4-0.4-1-0.4-1.4,0L19,12.6l-1.3-1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l1.3,1.3l-1.3,1.3
                        c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l1.3-1.3l1.3,1.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
                        c0.4-0.4,0.4-1,0-1.4L20.4,14l1.3-1.3C22.1,12.3,22.1,11.7,21.7,11.3z"
          />
          <path
            d="M19.4,20.1c-0.1,0-2.2-1.1-4.4-1.1c-2.2,0-4.4,1.1-4.4,1.1c-0.5,0.2-0.7,0.8-0.4,1.3c0.2,0.4,0.5,0.5,0.9,0.5v0h8v0
                        c0.4,0,0.7-0.2,0.9-0.6C20.1,21,19.9,20.4,19.4,20.1z"
          />
          <path
            d="M27,13h-1.1c-0.5-3.4-3.4-6-6.9-6h-1.6l2.3-2.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L15,6.6l-3.3-3.3c-0.4-0.4-1-0.4-1.4,0
                        s-0.4,1,0,1.4L12.6,7H11c-3.5,0-6.4,2.6-6.9,6H3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h1v10c0,0.6,0.4,1,1,1h20c0.6,0,1-0.4,1-1V15h1
                        c0.6,0,1-0.4,1-1C28,13.4,27.6,13,27,13z M24,24H6V14c0-2.8,2.2-5,5-5h8c2.8,0,5,2.2,5,5V24z"
          />
        </g>
        <rect fill="none" width="30" height="30" />
      </g>
    </svg>
  );
}

export default Robot;
