import fetch from './fetch';

async function updateProfile(token) {
  const url = 'api/profiles/my';
  return await fetch(url, 'PUT', {}, token);
}

const services = {
  updateProfile
};

export default services;
