import types from './types';
import { profilesService, loginService } from '../../services';

const getToken = getState => {
  const state = getState();
  return state.authentication.token;
};

const actions = {
  updateProfile: useLegacy => async (dispatch, getState) => {
    dispatch({ type: types.updateProfile });

    const token = getToken(getState);
    try {
      await profilesService.updateProfile(token);
      const url = await loginService.login(token, useLegacy);
      window.location.replace(url);
    } catch (error) {
      const { response } = error;
      dispatch({ type: types.updateProfileFailed, error: response });
      throw error;
    }
  }
};

export default actions;
